const colors = {
  example1: {
    primary: '#003153',
    secondary: '#fff',
    accent: '#333'
  },
  example2: {
    primary: '#fff',
    secondary: '#003153',
    accent: '#333'
  }
};

export default colors.example2;
